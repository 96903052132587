import React from 'react';
import { ArrowRight } from 'lucide-react';

const SuccessStories = () => {
  const stories = [
    {
      client: 'Modern Crafts',
      industry: 'Home-Based Business',
      challenge: 'Struggled to scale their handmade crafts business beyond local markets.',
      solution: 'Built an e-commerce website and mobile app integrated with social media and payment gateways.',
      results: [
        '300% increase in online sales within 6 months',
        'Expanded customer base across 3 countries',
        'Streamlined order and inventory management',
      ],
      image: 'https://images.unsplash.com/photo-1602526213276-16ce1f3f67cb?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    },
    {
      client: 'InstaFashion Hub',
      industry: 'Instagram-Based Business',
      challenge: 'Limited growth due to reliance on manual orders and Instagram DMs.',
      solution: 'Transformed their Instagram shop into a fully functional e-commerce platform with automated workflows.',
      results: [
        'Reduced manual order handling by 90%',
        'Increased conversion rates by 50%',
        'Boosted customer satisfaction with real-time tracking features',
      ],
      image: 'https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    },
    {
      client: 'Startup Connect',
      industry: 'Startups',
      challenge: 'Needed a strong digital presence to launch their new product effectively.',
      solution: 'Developed a professional website and mobile app with branding and marketing support.',
      results: [
        'Successfully launched within 3 months',
        'Generated 10,000 downloads in the first month',
        'Built a loyal customer base through targeted digital campaigns',
      ],
      image: 'https://images.unsplash.com/photo-1593642532973-d31b6557fa68?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
    },
  ];

  return (
    <div className="min-h-screen pt-16">
      {/* Header */}
      <div className="bg-blue-600 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold text-center mb-6">
            Success Stories
          </h1>
          <p className="text-xl text-center text-blue-100 max-w-3xl mx-auto">
            Real results from our clients. See how we’ve helped businesses succeed in the digital era.
          </p>
        </div>
      </div>

      {/* Success Stories */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="space-y-20">
          {stories.map((story, index) => (
            <div
              key={index}
              className={`flex flex-col ${
                index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
              } gap-8 items-center`}
            >
              <div className="w-full lg:w-1/2">
                <img
                  src={story.image}
                  alt={story.client}
                  className="rounded-lg shadow-lg w-full h-[400px] object-cover"
                />
              </div>
              <div className="w-full lg:w-1/2 space-y-6">
                <div className="inline-block px-4 py-2 bg-blue-100 text-blue-600 rounded-full text-sm font-semibold">
                  {story.industry}
                </div>
                <h2 className="text-3xl font-bold text-gray-900">{story.client}</h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">Challenge</h3>
                    <p className="text-gray-600">{story.challenge}</p>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">Solution</h3>
                    <p className="text-gray-600">{story.solution}</p>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">Results</h3>
                    <ul className="space-y-2">
                      {story.results.map((result, resultIndex) => (
                        <li key={resultIndex} className="flex items-center text-gray-600">
                          <ArrowRight className="w-5 h-5 text-blue-600 mr-2" />
                          {result}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gray-100 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Ready to Transform Your Business?
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Let’s discuss how we can help you achieve your business goals.
          </p>
          <a
            href="/contact"
            className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200"
          >
            Start Your Journey
          </a>
        </div>
      </div>
    </div>
  );
};

export default SuccessStories;
