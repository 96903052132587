import React from 'react';
import { Globe, Smartphone, Layers, Target } from 'lucide-react';

const About = () => {
  const stats = [
    { icon: <Globe className="w-8 h-8 text-blue-600" />, value: '100+', label: 'Businesses Transformed' },
    { icon: <Smartphone className="w-8 h-8 text-blue-600" />, value: '150+', label: 'Websites & Apps Delivered' },
    { icon: <Layers className="w-8 h-8 text-blue-600" />, value: '10+', label: 'Years in Digital Services' },
    { icon: <Target className="w-8 h-8 text-blue-600" />, value: '95%', label: 'Client Satisfaction Rate' },
  ];

  const team = [
    {
      name: 'Alice Carter',
      role: 'Founder & CEO',
      image: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
      bio: 'Alice brings over a decade of experience in business digitization and helping small businesses thrive online.',
    },
    {
      name: 'David Johnson',
      role: 'Lead Developer',
      image: 'https://images.unsplash.com/photo-1595152772835-219674b2a8a6?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
      bio: 'David specializes in creating seamless websites and mobile apps tailored to business needs.',
    },
    {
      name: 'Sophia Martinez',
      role: 'Marketing Strategist',
      image: 'https://images.unsplash.com/photo-1580894732444-8e28b356c3fc?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
      bio: 'Sophia helps businesses craft their online presence through innovative digital marketing strategies.',
    },
  ];

  return (
    <div className="min-h-screen pt-16">
      {/* Hero Section */}
      <div className="bg-blue-600 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold text-center mb-6">
            About Us
          </h1>
          <p className="text-xl text-center text-blue-100 max-w-3xl mx-auto">
            Helping businesses go digital with customized websites, mobile apps, and marketing strategies.
          </p>
        </div>
      </div>

      {/* Mission Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Mission</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            To empower home-based businesses, Instagram entrepreneurs, and startups by transforming their ideas into impactful digital platforms.
          </p>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <div className="flex justify-center mb-4">{stat.icon}</div>
              <div className="text-3xl font-bold text-gray-900 mb-2">{stat.value}</div>
              <div className="text-gray-600">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Team Section */}
      {/* <div className="bg-gray-100 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Meet Our Team</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              The passionate professionals behind your digital transformation.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {team.map((member, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-full h-64 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{member.name}</h3>
                  <p className="text-blue-600 font-medium mb-4">{member.role}</p>
                  <p className="text-gray-600">{member.bio}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* Values Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Values</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Our commitment to excellence drives everything we do.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            {
              title: 'Innovation',
              description: 'Crafting solutions that keep businesses ahead in the digital world.',
            },
            {
              title: 'Client-Centric',
              description: 'Prioritizing client needs and delivering tailor-made results.',
            },
            {
              title: 'Integrity',
              description: 'Building trust through transparent and ethical practices.',
            },
          ].map((value, index) => (
            <div key={index} className="text-center p-6">
              <h3 className="text-xl font-bold text-gray-900 mb-4">{value.title}</h3>
              <p className="text-gray-600">{value.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
