import React from "react";
import { Facebook, Instagram, Twitter } from "lucide-react";

const Footer = () => {
  return (
    <footer className="bg-[#05173b] text-white">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-12">
        {/* Top Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 border-b border-gray-700 pb-8">
          {/* Quick Contact */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Contact</h4>
            <ul className="space-y-2">
              <li className="text-gray-400">+(91) 8610440021</li>
              <li className="text-gray-400">support@shoptown.com</li>
            </ul>
          </div>

          {/* Our Address */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Our Location</h4>
            <p className="text-gray-400">
              Coimbatore, Tamil Nadu, India.
            </p>
          </div>

          {/* Follow Us */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Follow Us</h4>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-white">
                <Facebook />
              </a>
              <a href="#" className="text-gray-400 hover:text-white">
                <Instagram />
              </a>
              <a href="#" className="text-gray-400 hover:text-white">
                <Twitter />
              </a>
            </div>
          </div>

          {/* Newsletter */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Subscribe to Updates</h4>
            <form className="flex">
              <input
                type="email"
                placeholder="Your Email Address"
                className="bg-[#031226] border border-gray-600 text-gray-400 px-4 py-2 rounded-l w-full"
              />
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-8 mt-12 pt-8">
          {/* Logo & Description */}
          <div className="lg:col-span-2">
            <div className="flex items-center space-x-4">
              <h4 className="text-2xl font-semibold text-blue-500">SHOP<span className="text-yellow-500">TOWN</span></h4>
            </div>
            <p className="text-gray-400 mt-4">
              We specialize in empowering businesses to go digital. Whether you're a home-based entrepreneur, an Instagram seller, or launching a startup, our custom websites and apps are designed to make your business thrive in the digital space.
            </p>
          </div>

          {/* Company Links */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Company</h4>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Meet Our Team
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Success Stories
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  News & Updates
                </a>
              </li>
            </ul>
          </div>

          {/* Services */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Services</h4>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Website Development
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Mobile App Development
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Digital Branding
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  E-Commerce Solutions
                </a>
              </li>
            </ul>
          </div>

          {/* Resources */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Resources</h4>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  FAQ
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Pricing Plans
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Contact Support
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-white">
                  Blogs & Articles
                </a>
              </li>
            </ul>
          </div>

          {/* Careers */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Careers</h4>
            <p className="text-gray-400 mb-4">
              Join us and help shape the future of digital businesses.
            </p>
            <a
              href="#"
              className="text-yellow-400 hover:text-blue-500 font-semibold"
            >
              Explore Careers →
            </a>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 text-center border-t border-gray-700 pt-6">
          <p className="text-gray-400">
            © 2025 Shoptown. All Rights Reserved. Built with ❤️ for businesses that aspire to grow.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
