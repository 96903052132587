import { Play } from 'lucide-react';
import { collection, addDoc } from "firebase/firestore";
import db from "../firebaseConfig.js";
import { toast, ToastContainer } from 'react-toastify';

function FormSection() {

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const form = e.currentTarget;

        const formData = {
            fullName: (form.elements.namedItem("fullName") as HTMLInputElement)?.value.trim(),
            companyName: (form.elements.namedItem("companyName") as HTMLInputElement)?.value.trim(),
            phoneNumber: (form.elements.namedItem("phoneNumber") as HTMLInputElement)?.value.trim(),
            emailAddress: (form.elements.namedItem("emailAddress") as HTMLInputElement)?.value.trim(),
            annualRevenue: (form.elements.namedItem("annualRevenue") as HTMLSelectElement)?.value.trim(),
            businessNature: (form.elements.namedItem("businessNature") as HTMLSelectElement)?.value.trim(),
            query: (form.elements.namedItem("query") as HTMLTextAreaElement)?.value.trim(),
        };

        // Validate all required fields
        if (
            !formData.fullName ||
            !formData.companyName ||
            !formData.phoneNumber ||
            !formData.emailAddress ||
            !formData.annualRevenue ||
            !formData.businessNature ||
            !formData.query
        ) {
            toast.error("Please fill out all fields before submitting the form.");
            return;
        }

        try {
            const docRef = await addDoc(collection(db, "formSubmissions"), formData);
            toast.success(`Form submitted successfully! Document ID: ${docRef.id}`);
            form.reset(); // Reset the form on success
        } catch (error) {
            console.error("Error adding document: ", error);
            toast.error("Error submitting the form. Please try again.");
        }
    };



    return (
        <section className="bg-[#05173b] text-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                    {/* Left Content */}
                    <div className="space-y-8 text-white">
                        <h2 className="text-4xl font-bold">
                            Empowering Your Business with Digital Excellence!
                        </h2>

                        <p className="text-white/90">
                            Transform your home-based business, Instagram store, or new online venture into a fully digitized platform with stunning websites and mobile apps.
                            Our team ensures a seamless transition to the digital world.
                        </p>

                        <p className="text-white/90">
                            With years of experience, we specialize in creating tailored digital solutions, delivering timely services, and resolving every challenge so your business can thrive in the competitive online marketplace.
                        </p>


                        <div className="flex items-center gap-6">
                            <button className="px-6 py-3 bg-white text-blue-600 rounded-md font-semibold hover:bg-white/90 transition-colors">
                                Pricing & Plans
                            </button>

                            <button className="flex items-center gap-2 text-white hover:text-white/90 transition-colors">
                                <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center">
                                    <Play className="w-4 h-4 text-blue-600 ml-1" />
                                </div>
                                Play Video
                            </button>
                        </div>


                    </div>

                    {/* Right Form */}
                    <div className="bg-white rounded-lg p-8 shadow-lg">
                        <h3 className="text-2xl font-bold text-gray-900 mb-6">
                            Request A Quote
                        </h3>

                        <form className="space-y-6" onSubmit={handleSubmit}>
                            {/* Name and Company Name */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Full Name *
                                    </label>
                                    <input
                                        type="text"
                                        name="fullName"
                                        className="w-full px-4 py-2 text-black border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter your full name"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Company Name *
                                    </label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        className="w-full px-4 py-2 border text-black  border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter your company name"
                                        required
                                    />
                                </div>
                            </div>

                            {/* Phone and Email */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Phone Number *
                                    </label>
                                    <input
                                        type="tel"
                                        name="phoneNumber"
                                        className="w-full px-4 py-2 border text-black  border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter your phone number"
                                        required
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Email Address *
                                    </label>
                                    <input
                                        type="email"
                                        name="emailAddress"
                                        className="w-full px-4 py-2 border text-black  border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter your email address"
                                        required
                                    />
                                </div>
                            </div>

                            {/* Revenue and Business Nature */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Company Annual Revenue
                                    </label>
                                    <select
                                        name="annualRevenue"
                                        defaultValue=""
                                        className="w-full px-4 py-2 text-black border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Revenue
                                        </option>
                                        <option>Less than $1M</option>
                                        <option>$1M - $5M</option>
                                        <option>$5M - $10M</option>
                                        <option>Above $10M</option>
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Nature of Business
                                    </label>
                                    <select
                                        name="businessNature"
                                        defaultValue=""
                                        className="w-full px-4 py-2 text-black border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Business
                                        </option>
                                        <option>Retail & E-Commerce</option>
                                        <option>Manufacturing</option>
                                        <option>Others</option>
                                    </select>
                                </div>
                            </div>

                            {/* Query Description */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Your Query
                                </label>
                                <textarea
                                    name="query"
                                    rows={4}
                                    className="w-full px-4 text-black  py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Describe your requirements or inquiry in detail."
                                ></textarea>
                            </div>

                            {/* Privacy & Terms */}
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="privacy"
                                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                    required
                                />
                                <label htmlFor="privacy" className="ml-2 text-sm text-gray-600">
                                    I accept the{" "}
                                    <a
                                        href="#"
                                        className="text-blue-500 hover:underline"
                                    >
                                        privacy policy
                                    </a>{" "}
                                    and terms of service.
                                </label>
                            </div>

                            {/* Submit Button */}
                            <button
                                type="submit"
                                className="w-full py-3 bg-blue-600 text-white rounded-md font-semibold hover:bg-blue-700 transition-colors"
                            >
                                Submit Request
                            </button>
                        </form>
                    </div>

                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

        </section>
    );
}

export default FormSection;