import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X, Phone } from 'lucide-react';
import ShoptownLogo from '../assets/images/shoptown.png';
import { MdOutlineDoubleArrow } from "react-icons/md";
import "./components.css"
const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();

  const navLinks = [
    { path: '/services', label: 'Services' },
    { path: '/success-stories', label: 'Success Stories' },
    { path: '/about', label: 'About Us' },
    { path: '/contact', label: 'Contact Us' },
  ];

  const isActive = (path: string) => location.pathname === path;

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo */}
          <div className="flex items-center space-x-3">
            <Link to="/" className="flex items-center">
              <img
                src={ShoptownLogo}
                alt="SectionBack"
                className="h-52"
              />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`${isActive(link.path)
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'text-blck hover:text-blue-600'
                  } px-3 py-2 text-lg font-medium transition-colors duration-200`}
              >
                {link.label}
              </Link>
            ))}
            <Link
              to="/contact"
              className="bg-black text-white px-4 py-2 text-sm font-medium transition-colors w-48 flex items-center justify-between group"
            >
              SCHEDULE A CALL
              <MdOutlineDoubleArrow className="ml-2 text-lg animate-bounce-arrow" />
            </Link>

          </div>


          {/* Mobile Navigation Button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-600 hover:text-blue-600 focus:outline-none"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {isOpen && (
        <div className="md:hidden bg-white">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`${isActive(link.path)
                  ? 'text-blue-600 bg-blue-50'
                  : 'text-gray-600 hover:text-blue-600 hover:bg-blue-50'
                  } block px-3 py-2 rounded-md text-base font-medium transition-colors duration-200`}
                onClick={() => setIsOpen(false)}
              >
                {link.label}
              </Link>
            ))}
            <Link
              to="/quote"
              className="bg-black text-white  px-4 py-2 text-sm font-medium transition-colors"
            >
              SCHEDULE A CALL   <MdOutlineDoubleArrow />
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
