import React from 'react';
import { Cloud, ShoppingBag, Smartphone, Globe, Users, TrendingUp } from 'lucide-react';

const Services = () => {
  const services = [
    {
      icon: <ShoppingBag className="w-12 h-12 text-blue-600" />,
      title: 'Digital Transformation for Home Businesses',
      description:
        'Helping home-based businesses scale digitally by building their online presence and equipping them with tools to reach a larger audience.',
      features: [
        'Professional Website Development',
        'Mobile-Friendly Online Stores',
        'SEO & Social Media Integration',
        'Easy-to-Manage Dashboards',
      ],
    },
    {
      icon: <Globe className="w-12 h-12 text-blue-600" />,
      title: 'Instagram to E-Commerce Conversion',
      description:
        'Transform your Instagram-based business into a fully functional e-commerce website and app to streamline operations and grow your customer base.',
      features: [
        'Customized Online Store Setup',
        'Instagram Shop Integration',
        'Social Media Ad Management',
        'Analytics & Reporting for Growth',
      ],
    },
    {
      icon: <Smartphone className="w-12 h-12 text-blue-600" />,
      title: 'Startup Solutions for Online Businesses',
      description:
        'End-to-end digital solutions for startups to ensure a successful launch, covering everything from branding to technology implementation.',
      features: [
        'Business Website & Mobile App Development',
        'Payment Gateway Integration',
        'Logo & Brand Identity Design',
        'Online Marketing Strategies',
      ],
    },
    {
      icon: <Users className="w-12 h-12 text-blue-600" />,
      title: 'Social Media Growth Services',
      description:
        'Expand your reach and boost sales with tailored social media strategies that engage your audience and build brand loyalty.',
      features: [
        'Instagram & Facebook Ads Management',
        'Content Creation & Scheduling',
        'Influencer Collaboration Strategies',
        'Social Media Analytics',
      ],
    },
    {
      icon: <Cloud className="w-12 h-12 text-blue-600" />,
      title: 'E-Commerce Website & App Development',
      description:
        'Custom websites and mobile apps tailored to your business needs, providing customers with an exceptional shopping experience.',
      features: [
        'User-Friendly Design',
        'Secure Payment Gateways',
        'Product Inventory Management',
        'Multi-Device Compatibility',
      ],
    },
    {
      icon: <TrendingUp className="w-12 h-12 text-blue-600" />,
      title: 'Business Automation Solutions',
      description:
        'Save time and focus on growth by automating repetitive business processes with custom tools and integrations.',
      features: [
        'Inventory & Order Management Systems',
        'Automated Customer Support (Chatbots)',
        'CRM Integration for Lead Management',
        'Data-Driven Business Insights',
      ],
    },
  ];

  return (
    <div className="min-h-screen pt-16">
      {/* Header Section */}
      <div className="bg-blue-600 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold text-center mb-6">
            Our Services
          </h1>
          <p className="text-xl text-center text-blue-100 max-w-3xl mx-auto">
            Comprehensive IT solutions tailored to drive your business forward
          </p>
        </div>
      </div>

      {/* Services Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-shadow duration-200"
            >
              <div className="flex justify-center mb-6">{service.icon}</div>
              <h3 className="text-2xl font-bold text-gray-900 text-center mb-4">
                {service.title}
              </h3>
              <p className="text-gray-600 text-center mb-6">
                {service.description}
              </p>
              <ul className="space-y-2">
                {service.features.map((feature, featureIndex) => (
                  <li
                    key={featureIndex}
                    className="flex items-center text-gray-700"
                  >
                    <span className="w-2 h-2 bg-blue-600 rounded-full mr-2"></span>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* Call-to-Action Section */}
      <div className="bg-gray-100 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Ready to Transform Your Business?
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Let's work together to create a customized digital solution for your business needs.
          </p>
          <a
            href="/contact"
            className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200"
          >
            Get in Touch
          </a>
        </div>
      </div>
    </div>
  );
};

export default Services;
