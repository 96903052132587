import "./pages.css"
import { ArrowRight, Box, Package, Play, ShoppingBag, ShoppingCart, Video } from 'lucide-react';
import SectionBack from '../assets/images/sectionBack.png';
import SectionPerson from '../assets/images/sectionPerson.png';
import { Lightbulb, Compass, PiggyBank, HeartHandshake, Rocket, Cpu } from 'lucide-react';
import ProgressSection from '../components/ProgressSection';
import FormSection from '../components/FormSection';
import Testimonial from '../components/Testimonial';
import { LiaTshirtSolid } from "react-icons/lia";
import { GiBottledBolt } from "react-icons/gi";
import { FiWatch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";


const Home = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');  // Navigate to /contact
  };
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="relative w-full bg-gradient-to-br px-10 from-blue-50 to-blue-100 overflow-hidden">
        {/* Background image (right-side) */}
        <img
          src={SectionBack}
          alt="SectionBack"
          className="absolute right-0 top-0 h-full object-cover pointer-events-none"
        />

        {/* Floating product icons with random positions and animations */}
        <div className="absolute top-[10%] left-[15%] animate-bounce-all">
          <ShoppingCart className="w-8 h-8 text-yellow-300" />
        </div>
        <div className="absolute bottom-[20%] left-[25%] animate-bounce-all">
          <ShoppingBag className="w-8 h-8 text-purple-300" />
        </div>
        <div className="absolute top-[30%] left-[35%] animate-bounce-all">
          <Box className="w-8 h-8 text-orange-300" />
        </div>
        <div className="absolute top-[40%] right-[45%] animate-bounce-all">
          <Package className="w-8 h-8 text-green-300" />
        </div>
        <div className="absolute top-[50%] left-[55%] animate-bounce-all">
          <LiaTshirtSolid className="w-8 h-8 text-blue-300" />
        </div>
        <div className="absolute bottom-[60%] left-[65%] animate-bounce-all">
          <GiBottledBolt className="w-8 h-8 text-red-300" />
        </div>
        <div className="absolute top-[70%] left-[75%] animate-bounce-all">
          <FiWatch className="w-8 h-8 text-teal-300" />
        </div>
        <div className="absolute top-[25%] left-[70%] animate-bounce-all">
          <FiWatch className="w-8 h-8 text-teal-300" />
        </div>
        <div className="absolute top-[80%] right-[85%] animate-bounce-all">
          <ShoppingBag className="w-8 h-8 text-green-300" />
        </div>


        <div className="container mx-auto px-4 pt-20">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            {/* Left Column */}
            <div className="space-y-8">
              {/* New tag */}
              <div className="inline-flex items-center bg-blue-100 rounded-full px-4 py-1">
                <span className="text-blue-600 text-sm font-medium mr-2">New</span>
                <span className="text-gray-600 text-sm">
                  Startups to Achieve Unprecedented Growth →
                </span>
              </div>

              {/* Hero Content */}
              <div className="space-y-6">
                <h1 className="text-4xl lg:text-5xl font-bold leading-tight">
                  <span className="text-yellow-600">Shoptown </span> : Transform Your
                  <br />
                  Business Digitally
                </h1>
                <p className="text-gray-600 text-lg">
                  "We help startups scale, innovate, succeed with comprehensive suite
                  of services tailored to fuel your business journey.
                  Take your online business to the next level with professional websites and mobile apps.
                  Whether you're starting a new venture, running an Instagram-based store,
                </p>
              </div>

              {/* CTA Buttons */}
              <div className="flex flex-wrap gap-4">
                <button
                  onClick={handleClick}
                  className="bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors flex items-center">
                  <Play className="w-4 h-4 mr-2" />
                  Get Started Today
                </button>
                <button className="border border-gray-300 px-6 py-3 rounded-lg font-medium hover:bg-gray-50 transition-colors flex items-center">
                  <Video className="w-4 h-4 mr-2" />
                  Demo Video
                </button>
              </div>

              {/* Reviews */}
              {/* <div className="flex items-center space-x-4">
                <div className="flex -space-x-2">
                  {[1, 2, 3, 4].map((i) => (
                    <img
                      key={i}
                      src={`https://source.unsplash.com/random/100x100?face=${i}`}
                      alt={`Reviewer ${i}`}
                      className="w-8 h-8 rounded-full border-2 border-white"
                    />
                  ))}
                </div>
                <div>
                  <div className="flex items-center">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <svg
                        key={star}
                        className="w-5 h-5 text-yellow-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                    <span className="ml-2 font-bold">5.0</span>
                  </div>
                  <p className="text-sm text-gray-600">Rated Best Over 15.7k Reviews</p>
                </div>
              </div> */}
            </div>

            {/* Right Column - Stats Cards */}
            <div className="relative">
              <img src={SectionPerson} alt="SectionPerson" />

              {/* Floating Stats Cards */}
              <div className="absolute top-10 -right-10 w-64 bg-white rounded-xl shadow-lg p-4 animate-[fadeIn_0.5s_ease-out]">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-600">New Businesses Digitized</p>
                    <p className="text-xl font-bold">345+</p>
                  </div>
                  <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center">
                    <div className="w-12 h-12 rounded-full border-4 border-blue-500 border-t-transparent animate-spin"></div>
                  </div>
                </div>
              </div>


              <div className="absolute bottom-20 -left-10 w-64 bg-white rounded-xl shadow-lg p-4 animate-[fadeIn_0.5s_ease-out]">
                <div>
                  <p className="text-sm text-gray-600">Monthly Platform Visits</p>
                  <p className="text-xl font-bold">163k</p>
                  <p className="text-sm text-gray-500">Dec 1 - Jan 1</p>
                  <div className="mt-2 flex space-x-1">
                    {[60, 80, 65, 75, 70, 85, 75].map((height, i) => (
                      <div
                        key={i}
                        className="w-6 bg-blue-500 rounded"
                        style={{ height: `${height}px` }}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>


              <div className="absolute bottom-40 -right-10 w-56 bg-white rounded-xl shadow-lg p-4 animate-[fadeIn_0.5s_ease-out]">
                <div>
                  <p className="text-sm text-gray-600">Revenue Generated for Clients</p>
                  <p className="text-xl font-bold">$4.2M</p>
                  <p className="text-sm text-green-500">↑ 15% vs last month</p>
                  <svg className="w-full h-12 mt-2" viewBox="0 0 100 30">
                    <path
                      d="M0 20 Q 20 10, 40 15 T 80 5 T 100 15"
                      fill="none"
                      stroke="#22c55e"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section className="py-16 bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-20">
            <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Why Choose Us?
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We're committed to delivering excellence through innovation, expertise, and dedication to your success.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {[
              {
                icon: <Lightbulb className="w-10 h-10" />,
                title: 'Tailored Solutions',
                description: 'Custom websites and mobile apps designed specifically for your unique business requirements'
              },
              {
                icon: <Compass className="w-10 h-10" />,
                title: 'Expert Guidance',
                description: 'Professional support to help you navigate your digital transformation journey seamlessly'
              },
              {
                icon: <PiggyBank className="w-10 h-10" />,
                title: 'Affordable Pricing',
                description: 'Premium digital services that deliver exceptional value without breaking your budget'
              },
              {
                icon: <HeartHandshake className="w-10 h-10" />,
                title: 'End-to-End Support',
                description: 'Comprehensive assistance from initial concept through to final implementation'
              },
              {
                icon: <Rocket className="w-10 h-10" />,
                title: 'Fast Delivery',
                description: 'Quick turnaround times to get your digital presence established rapidly'
              },
              {
                icon: <Cpu className="w-10 h-10" />,
                title: 'Cutting-Edge Technology',
                description: 'Latest tools and platforms ensuring your digital solutions are future-proof'
              }
            ].map((feature, index) => (
              <div
                key={index}
                className="group relative p-8 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
              >
                <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500 rounded-t-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-blue-50 text-blue-600 group-hover:text-blue-700 transition-colors duration-300">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-3">
                  {feature.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-[#05173b] mx-20 rounded py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
            Ready to Transform Your Business?
          </h2>
          <p className="text-xl text-blue-100 mb-8">
            Let's discuss how we can help you achieve your goals
          </p>
          <a
            href="/contact"
            className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 transition-colors duration-200"
          >
            Contact Us Today
            <ArrowRight className="ml-2" size={20} />
          </a>
        </div>
      </section>
      <ProgressSection />
      <Testimonial />
      <FormSection />
    </div>
  );
};

export default Home;