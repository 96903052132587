import { useState, useEffect } from 'react';

function ProgressBar({ label, percentage }: { label: string; percentage: number }) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(percentage);
  }, [percentage]);

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold text-gray-800">{label}</h3>
        <span className="text-lg font-semibold text-blue-600">{percentage}%</span>
      </div>
      <div className="h-3 bg-gray-100 rounded-full overflow-hidden">
        <div 
          className="h-full bg-blue-600 rounded-full transition-all duration-1000 ease-out"
          style={{ width: `${width}%` }}
        />
      </div>
    </div>
  );
}

function ProgressSection() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-8">
            <div className="space-y-4">
              <h2 className="text-4xl md:text-5xl font-bold text-navy-900 leading-tight">
                Increasing business success with technology
              </h2>
              <p className="text-lg text-gray-600">
                Each solution built with our expertise will be unique. You can customize almost anything in the appearance of your digital presence with only a few clicks. We ensure each implementation is tailored to your specific needs.
              </p>
            </div>

            <div className="space-y-6">
              <ProgressBar label="IT Solution & Management" percentage={86} />
              <ProgressBar label="Website & App Development" percentage={72} />
              <ProgressBar label="SEO & Digital Marketing" percentage={83} />
            </div>
          </div>

          <div className="relative">
            <div className="grid grid-cols-2 gap-4">
              <div className="relative z-10">
                <img
                  src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80"
                  alt="Team collaboration"
                  className="w-full h-64 object-cover rounded-2xl shadow-lg"
                />
              </div>
              <div className="relative z-10 mt-12">
                <img
                  src="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?auto=format&fit=crop&q=80"
                  alt="Business meeting"
                  className="w-full h-64 object-cover rounded-2xl shadow-lg"
                />
              </div>
              <div className="absolute top-0 right-0 w-full h-full bg-gradient-to-br from-blue-50 to-purple-50 rounded-3xl -z-10 transform translate-x-4 translate-y-4" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProgressSection;