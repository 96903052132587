// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWzVPwVjkEM-Io5_8BlWlhLaUk8yIOIGY",
  authDomain: "shoptown-c3130.firebaseapp.com",
  projectId: "shoptown-c3130",
  storageBucket: "shoptown-c3130.appspot.com",
  messagingSenderId: "840567567999",
  appId: "1:840567567999:web:346481742739802412f14a",
  measurementId: "G-G71636NYN7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export default db;
