import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight, Quote } from 'lucide-react';

interface Testimonial {
    id: number;
    name: string;
    role: string;
    quote: string;
    rating: number;
    image: string;
}

const testimonials: Testimonial[] = [
    {
        id: 1,
        name: "Rosy Flower Gaggero",
        role: "Ceo And Founder",
        quote: "I requested a UI update gig and received job excellent from Maria! talented young UI/UX designer who will beat your expectation! and quick feedback. Will definitely work with again!",
        rating: 3,
        image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=100&h=100&q=80"
    },
    {
        id: 2,
        name: "Joshua Liddell",
        role: "Company Owner",
        quote: "I requested a UI update gig and received job excellent from Maria! talented young UI/UX designer who will beat your expectation! and quick feedback. Will definitely work with again!",
        rating: 4,
        image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=crop&w=100&h=100&q=80"
    },
    {
        id: 3,
        name: "Arush Aggarwal",
        role: "Senior Manager",
        quote: "I requested a UI update gig and received job excellent from Maria! talented young UI/UX designer who will beat your expectation! and quick feedback. Will definitely work with again!",
        rating: 5,
        image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=100&h=100&q=80"
    }
];

function Testimonial() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isAutoPlaying, setIsAutoPlaying] = useState(true);

    const nextSlide = () => {
        setActiveIndex((current) => (current + 1) % testimonials.length);
    };

    const prevSlide = () => {
        setActiveIndex((current) =>
            current === 0 ? testimonials.length - 1 : current - 1
        );
    };

    useEffect(() => {
        let intervalId: number;

        if (isAutoPlaying) {
            intervalId = window.setInterval(() => {
                nextSlide();
            }, 5000); // Change slide every 5 seconds
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isAutoPlaying]);

    // Calculate visible testimonials (show 3 at a time)
    const visibleTestimonials = testimonials.slice(
        activeIndex,
        activeIndex + 3
    ).concat(
        testimonials.slice(0, Math.max(0, 3 - (testimonials.length - activeIndex)))
    );
    return (
        <div className=" bg-blue-50 flex flex-col items-center justify-center p-8">
            <div className="max-w-7xl mx-auto text-center">
                <h2 className="text-4xl md:text-5xl font-bold text-navy-900 mb-16">
                    Don't take our word, see<br />what our customers say
                </h2>

                <div className="relative">
                    <button
                        onClick={prevSlide}
                        className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-16 bg-blue-500 p-2 rounded-full text-white hover:bg-blue-600 transition-colors z-10"
                        aria-label="Previous slide"
                    >
                        <ChevronLeft className="w-6 h-6" />
                    </button>

                    <button
                        onClick={nextSlide}
                        className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-16 bg-blue-500 p-2 rounded-full text-white hover:bg-blue-600 transition-colors z-10"
                        aria-label="Next slide"
                    >
                        <ChevronRight className="w-6 h-6" />
                    </button>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {visibleTestimonials.map((testimonial) => (
                            <div
                                key={testimonial.id}
                                className="bg-white rounded-lg p-6 relative transition-all duration-500"
                            >
                                {/* Quote mark */}
                                <div className="absolute top-6 right-6 text-4xl text-emerald-100">
                                    "
                                </div>

                                {/* Star rating */}
                                <div className="flex gap-1 mb-4">
                                    {[...Array(5)].map((_, i) => (
                                        <svg
                                            key={i}
                                            className={`w-5 h-5 ${i < testimonial.rating ? 'text-blue-500' : 'text-blue-100'
                                                }`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                    ))}
                                </div>

                                {/* Testimonial text */}
                                <p className="text-gray-700 mb-8">"{testimonial.quote}"</p>

                                {/* Author info */}
                                <div className="bg-blue-500 -mx-6 -mb-6 p-4 rounded-b-lg flex items-center gap-4">
                                    <img
                                        src={testimonial.image}
                                        alt={testimonial.name}
                                        className="w-12 h-12 rounded-full object-cover"
                                    />
                                    <div className="text-white">
                                        <h3 className="font-semibold">{testimonial.name}</h3>
                                        <p className="text-emerald-50 text-sm">{testimonial.role}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex justify-center gap-2 mt-8">
                        {testimonials.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setActiveIndex(index)}
                                className={`w-2 h-2 rounded-full transition-colors ${index === activeIndex
                                    ? 'bg-blue-500'
                                    : 'bg-blue-200 hover:bg-blue-300'
                                    }`}
                                aria-label={`Go to slide ${index + 1}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;